/* eslint-disable max-len, @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react'
import '../../assets/css/custom.css'
import { GlobalOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import minijust_logo from '../../assets/img/minijust_logo.png'
import InstitutionUserService from '../../services/InstitutionUserService'
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Input,
  message,
  Space,
  Checkbox,
  Spin,
  Modal,
  Select, Alert
} from 'antd'
import { PHONE_PATTERN } from '../../constants/general'
import moment from 'moment/moment'
const { Option } = Select

const RequestBidSecurityFees: React.FC = () => {
  const [form] = Form.useForm()
  const [showForm, setShowForm] = useState(false)
  const [fullName, setBidderFullName] = useState('')
  const [phone_number, setPhoneNumber] = useState('')
  const [payment_date, setPaymentDate] = useState('')
  const [token, setToken] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const { ref_number } = useParams()
  const [loading, setLoading] = useState(false)
  const [declarationChecked, setDeclarationChecked] = useState(false)
  const [loadingRefund, setLoadingRefund] = useState(false)
  const [showOTPForm, setShowOTPForm] = useState(false)
  const [countdown, setCountdown] = useState(300000)
  const [isFormValidated, setIsFormValidated] = useState(true)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [transactionReference, setTransactionReference] = useState<string>('')
  const [otpValue, setOtpValue] = useState('')
  const [paymentAmount, setPaymentAmount] = useState('')
  const [refNumber, setdecodesValue] = useState<string>('')
  const [showWarningAlert2, setShowWarningAlert2] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showCancelMessage, setShowCancelMessage] = useState(false)
  const [targetPaymentDateValue, setTargetPaymentDateValue] = useState(false)
  const [showHello, setShowHello] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const [selectedBank, setSelectedBank] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [bankCode, setBankCode] = useState('')
  const [biddesRefundStatus, setRefundStatus] = useState()
  const [showInvalidReferenceNumberModal, setShowInvalidReferenceNumberModal] = useState(false)
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpValue(e.target.value)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOTPFormCancel = () => {
    setShowOTPForm(false)
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHello(true)
    }, 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const adjustedPaymentAmount = selectedBank === 'Bank of Kigali'
    ? paymentAmount
    : (Number(paymentAmount) - 1000).toString()

  const handleBankChange = (value: string | undefined): void => {
    const amount = Number(paymentAmount)
    const adjustedAmount = value === 'Bank of Kigali' ? amount : amount - 1000
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setSelectedBank(value)
    form.setFieldsValue({ payment_amount: adjustedAmount })
    setIsModalVisible(true)

    let code = ''

    switch (value) {
      case 'Bank of Kigali Plc':
        code = '40'
        break
      case 'BPR Bank Rwanda':
        code = '44'
        break
      case 'Equity Bank Rwanda Plc':
        code = '85'
        break
      case 'Ecobank Rwanda Plc':
        code = '11'
        break
      case 'Guaranty Trust Bank Rwanda Plc':
        code = '70'
        break
      case 'Access Bank Rwanda Plc':
        code = '15'
        break
      case 'Zigama Css':
        code = '75'
        break
      case 'URWEGO OPPORTUNITY BANK PLC':
        code = '45'
        break
      case 'Bank of Africa Rwanda Plc':
        code = '76'
        break
      case 'UNGUKA BANK':
        code = '80'
        break
      case 'AB BANK':
        code = '20'
        break
      case 'I & M BANK LIMITED':
        code = '10'
        break
      case 'Letshego Rwanda Plc':
        code = '21'
        break
      case 'COPEDU Plc':
        code = '27'
        break
      case 'Vision Fund Plc':
        code = '28'
        break
      case 'Compagnie Generale de Banque':
        code = '30'
        break
      case 'National Bank of Rwanda':
        code = '0'
        break
      case 'Development Bank of Rwanda Plc':
        code = '35'
        break
      case 'RIM Ltd':
        code = '32'
        break
      case 'Atlantique Microfinance Plc':
        code = '22'
        break
      case 'Umutanguha Finance Company Plc':
        code = '23'
        break
      case 'Duterimberere-IMF Plc':
        code = '24'
        break
      case 'Goshen Finance Plc':
        code = '26'
        break
      case 'Financial Safety Co Plc':
        code = '29'
        break
      case 'MUGANGA SACCO':
        code = '33'
        break
      case 'Inkunga Finance Plc':
        code = '31'
        break
      case 'Commercial Bank of Africa Rwanda PLC':
        code = '25'
        break
      case 'UMWALIMU SACCO':
        code = '90'
        break
      default:
        code = ''
        break
    }
    setBankCode(code)
    form.setFieldsValue({ bank_code: code })
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOk = () => {
    setIsModalVisible(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleCancel = () => {
    setIsModalVisible(false)
    setShowForm(false)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHello(true)
    }, 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOTPSubmit = () => {
    const otp = otpValue
    const payloadForVerifyOTP = {
      transaction_reference: transactionReference,
      otp
    }
    const confirmationPayload = {
      transaction_reference: transactionReference,
      status: 'RECEIVED REQUEST'
    }
    InstitutionUserService.verifyOtpForRequestedRefund(payloadForVerifyOTP)
      .then((respo: any) => {
        if (respo.status === 200) {
          setShowOTPForm(false)
          InstitutionUserService.RefundRequestConfirmation(confirmationPayload)
            .then(async (confRespo: any) => {
              if (confRespo.status === 200) {
                setShowSuccessAlert(true)
                const payload = {
                  RefundRequests: {
                    bank_code: form.getFieldValue('bank_code'),
                    transaction_reference: transactionReference,
                    beneficiary_bank_name: form.getFieldValue('beneficiary_bank_name'),
                    beneficiary_account_name: form.getFieldValue('beneficiary_bank_account_name'),
                    beneficiary_account_number: form.getFieldValue('beneficiary_account_number'),
                    amount: form.getFieldValue('payment_amount'),
                    status: 'RECEIVED REQUEST'
                  }
                }

                // Use await for better readability and to avoid promise chaining
                const res = await InstitutionUserService.TriggerUpdateRefundRequestForBidders(payload, token)

                if (res.status === 201) {
                  setShowOTPForm(true)
                  setLoadingRefund(false)
                  setIsFormValidated(true)
                  setTimeout(() => {
                    setLoadingRefund(false)
                  }, 5000)
                }

                form.resetFields()
                // void message.success('Refund Request Submitted. Waiting for being Refunded...')
              } else {
                void message.warning('Failed To Submit Your Request. Contact Us on iecms-support@minijust.gov.rw')
              }
            })
            .catch((error: any) => {
              if (error.response.status === 400) {
                void message.warning('Request Failed to be Submitted')
              }
            })
        }
      })
      .catch((error: any) => {
        if (error.response.status === 400) {
          // Handle error response
          void message.warning('Failed To Verify OTP')
        }
      })
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(timer)
          return 0
        }
        return prevCountdown - 1000
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      setLoading(true)
      if (ref_number != null) {
        try {
          const encryptedRefNumber = ref_number
          // const decodedValue = decodeURIComponent(escape(atob(encryptedRefNumber)))
          // setdecodesValue(decodedValue)
          const decodedValue = decodeURIComponent(escape(atob(encryptedRefNumber)))
          // Remove 7 characters from the left and right
          const trimmedValue = decodedValue.substring(7, decodedValue.length - 7)
          setdecodesValue(trimmedValue)
          const res = await InstitutionUserService.loadBidsDetailsForRefund(trimmedValue)
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (res && res.status === 200) {
            const bidderDetails = res.data
            form.setFieldsValue({
              bidder_full_name: bidderDetails?.bid?.bidder?.full_name,
              bidder_national_id_number: bidderDetails?.bid?.bidder?.nid,
              phone_number: bidderDetails?.bid?.bidder?.phone_number,
              property_id: bidderDetails?.bid?.auction?.property_id,
              transaction_reference: bidderDetails?.ref_number,
              execution_case_number: bidderDetails?.bid?.auction?.execution_case_no,
              bidder_id: bidderDetails?.bid?.bidder.bidder_id,
              bid_security_id: bidderDetails?.payment.bid_security_id,
              payment_date: bidderDetails?.payment?.payment_date,
              amount: bidderDetails?.payment?.amount,
              transaction_currency: bidderDetails.payment.currency
            })
            setTransactionReference(bidderDetails?.ref_number)
            setBidderFullName(bidderDetails?.bid?.bidder?.full_name)
            setPaymentDate(bidderDetails?.payment.payment_date)
            setPaymentAmount(bidderDetails?.payment.amount)
          } else {
            // eslint-disable-next-line no-console
            console.error('Error: Invalid response from server')
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error)
        } finally {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }

    void fetchData()
  }, [ref_number, form])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const onFinish = async (values: any): void => {
    setLoadingRefund(true)
    setIsFormSubmitted(true)
    setShowOTPForm(true)
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const paymentDate = moment(payment_date, 'YYYY-MM-DD HH:mm:ss')
    const targetPaymentDate = moment('2023-07-25', 'YYYY-MM-DD')
    const modifiedTargetPaymentDate = targetPaymentDate.toLocaleString()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setTargetPaymentDateValue(modifiedTargetPaymentDate)
    if (!paymentDate.isSameOrAfter(targetPaymentDate)) {
      setLoadingRefund(false)
      setShowWarningAlert2(true)
      // void message.warning(`Refund can only be triggered for payments starting from ${modifiedTargetPaymentDate}. Any Assistance Please contact us on iecms-support@minijust.gov.rw`)
      return
    }
    if (!declarationChecked) {
      setLoadingRefund(false)
      void message.warning('Please confirm the declaration part to be able to submit your Request.')
      return
    }
    // Here you should validate all form fields, and if they are valid, set isFormValidated to true
    form
      .validateFields()
      .then(() => {
        setIsFormValidated(true)
      })
      .catch(() => {
        setIsFormValidated(false)
        setLoadingRefund(false)
      })
    const payloadOTPForRefund = {
      transaction_reference: transactionReference,
      status: 'PENDING'
    }

    InstitutionUserService.createOtpCode(payloadOTPForRefund)
      .then((respo: any) => {
        if (respo.status === 200) {
          // Handle successful response if needed
        }
      })
      .catch((error: any) => {
        if ((Boolean(error.response)) && error.response.status === 404) {
          setShowOTPForm(false)
          // void message.warning('Records Already Exist')
        }
      })
    const validationResponse = await InstitutionUserService.validateRefundRequestByRefNumberAndStatus({
      transaction_reference: transactionReference,
      status: 'RECEIVED REQUEST'
    })
    if (validationResponse.data.length > 0) {
      setShowOTPForm(false)
      setShowInvalidReferenceNumberModal(true)
      const refundStatus = validationResponse.data[0].status
      setRefundStatus(refundStatus)
    }
  }
  return (
    <div className="w-full">
      <div className="navbar pay-bot-border navbar-background">
        <div className="flex">
          <div className="nav-logo">
            <img src={minijust_logo} alt="Logo"/>
          </div>
          <div className="nav-content text-sm md:text-2xl">
            <div className="nav-content-header">MINIJUST - IECMS</div>
            <div>Bid Security Online Payment and Refund</div>
          </div>
        </div>
        <div className="items-center font-semibold text-xs">
          <GlobalOutlined className="nav-globe"/>
          <div>ENGLISH</div>
        </div>
      </div>
      {isVisible && (
        <div className="scrollableContent">
          <Form
            name="normal_login"
            layout="vertical"
            className="login-form p-10"
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}
          >
            <Space direction="vertical" size={5} className="w-full content-background">
              <Card className="card-border h-full">
                <div
                  className="font-extrabold text-xl flex text-center items-center justify-center leading-10 text-gray-500 pb-2">
                  Please fill the form below for requesting refund
                </div>

                <div
                  className="green-color font-extrabold text-xl flex text-center items-center justify-center py-5">
                  Refund Request Details
                </div>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bidder's National ID Number"
                      name="bidder_national_id_number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Bidder's National ID Number"
                        }
                      ]}
                    >
                      <Input
                        maxLength={16}
                        placeholder="Enter Bidder's National ID Number" disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Phone Number"
                      name="phone_number"
                      rules={[{
                        required: true,
                        message: 'Please enter Phone Number'
                      },
                        {
                          message: 'Phone number should be either 4 digits or 10 digits.',
                          pattern: PHONE_PATTERN
                        }]}
                    >
                      <Input type='tel' placeholder="Enter Phone Number"
                             disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Property ID"
                      name="property_id"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Property ID'
                        }
                      ]}
                    >
                      <Input placeholder="Enter Property ID" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Execution Case Number"
                      name="execution_case_number"
                      rules={[{
                        required: true,
                        message: 'Please enter Execution Case Number'
                      }]}
                    >
                      <Input placeholder="Execution Case Number" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bid Reference Number"
                      name="transaction_reference"
                      rules={[{
                        required: true,
                        message: 'Please enter transaction reference'
                      }]}
                    >
                      <Input placeholder="Bid Reference Number" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Beneficiary Name"
                      name="bidder_full_name"
                      rules={[{
                        required: true,
                        message: 'Please enter Beneficial Name'
                      }]}
                    >
                      <Input placeholder="Beneficiary Account Name" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Deposit Bid Security Amount"
                      name="amount"
                      rules={[{
                        required: true,
                        message: 'Please enter Amount'
                      }]}
                    >
                      <Input type='text' placeholder="Amount" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Currency"
                      name="transaction_currency"
                      rules={[{
                        required: true,
                        message: 'Please provide the transaction currency'
                      }]}
                    >
                      <Input
                        maxLength={24}
                        placeholder="RWF" disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bid Security Payment Date"
                      name="payment_date"
                      rules={[
                        {
                          required: true,
                          message: 'Payment Date not Available'
                        }
                      ]}
                    >
                      <Input placeholder="Bid Payment Date" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bank Name"
                      name="beneficiary_bank_name"
                      rules={[
                        {
                          required: true,
                          message: "Please select the Bidder's Bank Name"
                        }
                      ]}
                    >
                      <Select placeholder="Select Beneficiary Bank" allowClear
                              onChange={handleBankChange}>
                        <Option value="Bank of Kigali Plc">Bank of Kigali Plc</Option>
                        <Option value="Equity Bank Rwanda Plc">Equity Bank Rwanda Plc</Option>
                        <Option value="BPR Bank Rwanda">BPR Bank Rwanda</Option>
                        <Option value="Ecobank Rwanda Plc">Ecobank Rwanda Plc</Option>
                        <Option value="Guaranty Trust Bank Rwanda Plc">Guaranty Trust Bank Rwanda Plc</Option>
                        <Option value="Access Bank Rwanda Plc">Access Bank Rwanda Plc</Option>
                        <Option value="Zigama Css">Zigama Css</Option>
                        <Option value="URWEGO OPPORTUNITY BANK PLC">URWEGO OPPORTUNITY BANK PLC</Option>
                        <Option value="Bank of Africa Rwanda Plc">Bank of Africa Rwanda Plc</Option>
                        <Option value="UNGUKA BANK">UNGUKA BANK</Option>
                        <Option value="AB BANK">AB BANK</Option>
                        <Option value="I&M Bank Rwanda Plc">I&M Bank Rwanda Plc</Option>
                        <Option value="Letshego Rwanda Plc">Letshego Rwanda Plc</Option>
                        <Option value="COPEDU Plc">COPEDU Plc</Option>
                        <Option value="Vision Fund Plc">Vision Fund Plc</Option>
                        <Option value="Compagnie Generale de Banque">Compagnie Generale de Banque</Option>
                        <Option value="National Bank of Rwanda">National Bank of Rwanda</Option>
                        <Option value="Development Bank of Rwanda Plc">Development Bank of Rwanda Plc</Option>
                        <Option value="RIM Ltd">RIM Ltd</Option>
                        <Option value="Atlantique Microfinance Plc">Atlantique Microfinance Plc</Option>
                        <Option value="Umutanguha Finance Company Plc">Umutanguha Finance Company Plc</Option>
                        <Option value="Duterimberere-IMF Plc">Duterimberere-IMF Plc</Option>
                        <Option value="Goshen Finance Plc">Goshen Finance Plc</Option>
                        <Option value="Financial Safety Co Plc">Financial Safety Co Plc</Option>
                        <Option value="MUGANGA SACCO">MUGANGA SACCO</Option>
                        <Option value="Inkunga Finance Plc">Inkunga Finance Plc</Option>
                        <Option value="UMWALIMU SACCO">UMWALIMU SACCO</Option>
                        <Option value="Commercial Bank of Africa Rwanda PLC">Commercial Bank of Africa Rwanda PLC</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Modal
                    title="Refund Notification"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <p>Selected Bank: <strong>{selectedBank}</strong></p>
                    <p>Amount to be Refunded:<strong>
                      {selectedBank === 'Bank of Kigali'
                        ? paymentAmount
                        : (Number(paymentAmount) - 1000)}
                    </strong>
                    </p>
                    <p><strong>Note:</strong> You will be charged <strong>1000RWF</strong> from Bank to other banks</p>
                  </Modal>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bank Account Number"
                      name="beneficiary_account_number"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Bank Account Number'
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: 'Please enter only numbers for Bank Account Number'
                        }
                      ]}
                    >
                      <Input
                        maxLength={24}
                        placeholder="Enter Bidder's Bank Account Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bank Account Name"
                      name="beneficiary_bank_account_name"
                      rules={[{
                        required: true,
                        message: 'Please enter Bank Account Name'
                      }]}
                    >
                      <Input type='text' placeholder="Enter Bank Account Name"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Refundable Amount"
                      name="payment_amount"
                      rules={[{
                        required: true,
                        message: 'Please enter Refundable Amount'
                      }]}
                    >
                      <input
                        type="text"
                        disabled
                        placeholder="Enter Refundable Amount"
                        style={{
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          padding: '8px',
                          width: '100%',
                          boxSizing: 'border-box'
                        }}
                        /* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */
                        value={selectedBank ? adjustedPaymentAmount : paymentAmount}
                        onChange={(e) => { setPaymentAmount(e.target.value) }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      // label="Bank Code"
                      name="bank_code"
                      rules={[{
                        required: true,
                        message: 'Please enter Bank Code'
                      }]}
                    >
                      <input
                        type="text"
                        disabled
                        className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden"
                        value={bankCode}
                        onChange={(e) => { /* handle any change if needed */ }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={16} xl={16}>
                    <Form.Item name="declaration" rules={[{ required: false }]}>
                      <Checkbox onChange={(e) => {
                        setDeclarationChecked(e.target.checked)
                      }}>
                        I, <b>{fullName}</b>, hereby declare that the provided
                        information about <b>Account Number, Bank Account Name
                        and Bank Name</b> are true, accurate and complete.
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>
                {/* Submit Button */}
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={16} xl={16}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit"
                              disabled={loadingRefund || !declarationChecked}>
                        {isEdit ? 'Update' : 'Continue'}
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row justify="center" align="middle" style={{ height: '100%' }}>
                  <Col xs={24} md={24} xl={24}>
                    {showSuccessAlert && (
                      <Modal
                        visible={showSuccessAlert}
                        onCancel={() => {
                          setShowSuccessAlert(false)
                        }}
                        footer={null}
                        centered
                      >
                        <Alert
                          message="Informational Notes"
                          /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                          description="Refund Request Submitted. Waiting to be Refunded..."
                          type="info"
                          showIcon
                          style={{
                            width: '100%'
                          }} // Set alert background color
                          onClose={() => {
                            setShowSuccessAlert(false)
                          }}
                        />
                      </Modal>
                    )}
                  </Col>
                </Row>

              </Card>
            </Space>
          </Form>
          <Modal
            title="Enter the provided code to submit your Request"
            visible={showOTPForm && isFormSubmitted && isFormValidated && declarationChecked}
            onCancel={handleOTPFormCancel}
            footer={[
              <Button key="cancel" onClick={handleOTPFormCancel}>Cancel</Button>,
              <Button key="submit" type="primary" onClick={handleOTPSubmit}>Submit</Button>
            ]}
            className="custom-modal"
            keyboard={false}
            maskClosable={false}
          >
            <Form.Item name="otp">
              <Input placeholder="Enter Code" value={otpValue} onChange={handleInputChange}/>
            </Form.Item>
            <p className="msgOTP">
              We just sent you an SMS on this phone number: ******** with an OTP (One Time
              Password).
              Enter the OTP in the form above to confirm your request.
            </p>
            <p>
              The OTP will expire in 5 minutes <code
              className="font-semibold ml-2">{moment(countdown).format('mm:ss')}</code>.
              {countdown === 0 && <Button type="link">Click here to resend SMS.</Button>}
            </p>
          </Modal>
          <Modal
            title="Notification"
            visible={showInvalidReferenceNumberModal}
            onCancel={() => {
              setShowInvalidReferenceNumberModal(false)
              // setShowCancelMessage(true)
              setShowForm(false)
            }}
            footer={null}
            centered
          >
            {showHello && (
              <>
                <p>
                  The Refund Request already exists with the same reference number: {transactionReference}
                </p>
                <p>
                  Your Request Current Status is: {biddesRefundStatus}
                </p>
              </>
            )}
          </Modal>

          {/* Other Modals */}
          {/* ... */}

          {showCancelMessage && (
            <div className="cancel-message">
              <div className="separator"></div>
              <h3>Thank you for using our services.</h3>
              <div className="separator"></div>
              <p>For any other support, please contact us
                on <strong>iecms-support@minijust.gov.rw</strong>.</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default RequestBidSecurityFees
