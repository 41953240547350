/* eslint-disable max-len, @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react'
import '../../assets/css/custom.css'
import { GlobalOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import minijust_logo from '../../assets/img/minijust_logo.png'
import InstitutionUserService from '../../services/InstitutionUserService'
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Input,
  message,
  Space,
  Checkbox,
  Spin,
  Modal,
  Alert
} from 'antd'
import { PHONE_PATTERN } from '../../constants/general'
import moment from 'moment/moment'

const MakeRefundRequest: React.FC = () => {
    const [form] = Form.useForm()
    const [showForm, setShowForm] = useState(false)
    const [fullName, setBidderFullName] = useState('')
    const [phone_number, setPhoneNumber] = useState('')
    const [payment_date, setPaymentDate] = useState('')
    const [token, setToken] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const { ref_number } = useParams()
    const [loading, setLoading] = useState(false)
    const [declarationChecked, setDeclarationChecked] = useState(false)
    const [loadingRefund, setLoadingRefund] = useState(false)
    const [showOTPForm, setShowOTPForm] = useState(false)
    const [countdown, setCountdown] = useState(300000)
    const [isFormValidated, setIsFormValidated] = useState(true)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [transactionReference, setTransactionReference] = useState<string>('')
    const [otpValue, setOtpValue] = useState('')
    const [showBankTransferChargeModal, setShowBankTransferChargeModal] = useState(false)
    const [paymentAmount, setPaymentAmount] = useState('')
    const [refundableAmount, setRefundableAmount] = useState('0')
    const [fetchedBankCode, setBankCode] = useState('')
    const [showWarningAlert, setShowWarningAlert] = useState(false)
    const [showWarningAlert2, setShowWarningAlert2] = useState(false)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)
    const [showCancelMessage, setShowCancelMessage] = useState(false)
    const [targetPaymentDateValue, setTargetPaymentDateValue] = useState(false)
    const [showBankCodeModal, setShowBankCodeModal] = useState(false)
    const [showHello, setShowHello] = useState(false)
    const [showInvalidReferenceNumberModal, setShowInvalidReferenceNumberModal] = useState(false)
    const [isVisible, setIsVisible] = useState(true)
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleAgree = () => {
    setShowBankTransferChargeModal(false)
    setShowForm(true)
  }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOtpValue(e.target.value)
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const handleOTPFormCancel = () => {
        setShowOTPForm(false)
    }
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHello(true)
    }, 3000)
    return () => { clearTimeout(timer) }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHello(true)
    }, 3000)
    return () => { clearTimeout(timer) }
  }, [])

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const handleOTPSubmit = () => {
        const otp = otpValue
        InstitutionUserService.getLastInsertedData()
          .then((res: any) => {
              const verifyData = res.data[0]
              // setTransactionReference(verifyData.transaction_reference)
              const payloadForVerifyOTP = {
                  transaction_reference: verifyData.transaction_reference,
                  otp
              }
              const confirmationPayload = {
                  transaction_reference: verifyData.transaction_reference,
                  status: 'RECEIVED REQUEST'
              }
              InstitutionUserService.verifyOtpToSendRequest(payloadForVerifyOTP)
                .then((respo: any) => {
                    if (respo.status === 200) {
                        setShowOTPForm(false)
                        InstitutionUserService.RefundRequestConfirmation(confirmationPayload)
                          .then((confRespo: any) => {
                              if (confRespo.status === 200) {
                                setShowSuccessAlert(true)
                                  // void message.success('Refund Request Submitted. Waiting for being Refunded...')
                              } else {
                                  void message.warning('Failed To Submit Your Request. Contact Us on iecms-support@minijust.gov.rw')
                              }
                          })
                          .catch((error: any) => {
                              if (error.response.status === 400) {
                                  void message.warning('Request Failed to be Submitted')
                              }
                          })
                    }
                })
                .catch((error: any) => {
                    if (error.response.status === 400) {
                        // Handle error response
                        void message.warning('Failed To Verify OTP')
                    }
                })
          })
          // eslint-disable-next-line n/handle-callback-err
          .catch((error: any) => {
              void message.warning('Failed To Load Data. Contact Us on iecms-support@minijust.gov.rw')
          })
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 0) {
                    clearInterval(timer)
                    return 0
                }
                return prevCountdown - 1000
            })
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      setLoading(true)
      if (ref_number != null) {
        try {
          const res = await InstitutionUserService.validateBidderForRefund(ref_number)

          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (res && res.status === 200) {
            const data = res.data

            form.setFieldsValue({
              bidder_full_name: `${data?.bidder?.firstName} ${data?.bidder?.lastName}`,
              bidder_national_id_number: data?.bid?.identityNumber,
              phone_number: data?.bid?.phoneNumber,
              property_id: data?.bid?.propertyId,
              transaction_reference: data?.bid?.bidSecurityRefNumber,
              execution_case_number: data?.bid?.executionCaseNumber,
              beneficiary_account_number: data?.bid?.bankAccountNumber,
              beneficiary_bank_name: data?.bid?.bankName,
              beneficiary_account_name: data?.bid?.bankAccountName,
              // beneficiary_account_name: `${data?.bidder?.firstName} ${data?.bidder?.lastName}`,
              beneficiary_bank_account_name: data?.bid?.bankAccountName,
              bank_code: data?.bid?.bankCode
            })

            // Set states using extracted data
            setBankCode(data?.bid?.bankCode)
            setTransactionReference(data?.bid?.bidSecurityRefNumber)
            setBidderFullName(data?.bid?.bankAccountName)

            // Load additional details using the extracted transaction reference
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const transactionReference = data?.bid?.bidSecurityRefNumber
            if (transactionReference !== null) {
              const detailsRes = await InstitutionUserService.loadBidsDetailsForRefund(transactionReference)
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              if (detailsRes && detailsRes.status === 200) {
                const paymentData = detailsRes.data
                let amountValue = parseInt(String(paymentData?.payment.amount)).toLocaleString()
                if (fetchedBankCode !== '040') {
                  amountValue = (parseInt(String(paymentData?.payment.amount)) - 1000).toLocaleString()
                }
                form.setFieldsValue({
                  bidder_id: paymentData?.bid?.bidder.bidder_id,
                  bid_security_id: paymentData?.payment.bid_security_id,
                  payment_date: paymentData?.payment?.payment_date,
                  amount: paymentData?.payment?.amount,
                  transaction_currency: paymentData.payment.currency,
                  refundable_bid_security_amount: amountValue
                })
                setPaymentDate(paymentData?.payment.payment_date)
                setPaymentAmount(paymentData?.payment.amount)
              }
            }
          } else {
            // eslint-disable-next-line no-console
            console.error('Error: Invalid response from server')
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error)
        } finally {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }

    void fetchData()
  }, [ref_number, form, fetchedBankCode])

  useEffect(() => {
      const bankCode = form.getFieldValue('bank_code')
      setBankCode(bankCode)
      // eslint-disable-next-line no-console
      if (bankCode !== '040') {
        if (bankCode !== null && bankCode !== undefined && bankCode !== '') {
          setShowBankTransferChargeModal(true)
          setShowBankCodeModal(false)
          setShowCancelMessage(false)
          const bailiffRefundableAmount = Number(paymentAmount) - 1000
          setRefundableAmount(bailiffRefundableAmount.toString())
        } else {
          setShowBankTransferChargeModal(false)
          setShowBankCodeModal(true)
        }
      } else {
        if (bankCode !== null && bankCode !== undefined && bankCode !== '') {
          setShowBankTransferChargeModal(true)
          setShowBankCodeModal(false)
          setShowCancelMessage(false)
          setRefundableAmount(paymentAmount)
        } else {
          setShowBankTransferChargeModal(false)
          setShowBankCodeModal(true)
        }
      }
    },
    [form, paymentAmount])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const onFinish = async (values: any): void => {
        setLoadingRefund(true)
        setIsFormSubmitted(true)
    // eslint-disable-next-line @typescript-eslint/no-shadow
        const paymentDate = moment(payment_date, 'YYYY-MM-DD HH:mm:ss')
        const targetPaymentDate = moment('2023-07-25', 'YYYY-MM-DD')
        const modifiedTargetPaymentDate = targetPaymentDate.toLocaleString()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setTargetPaymentDateValue(modifiedTargetPaymentDate)
        if (!paymentDate.isSameOrAfter(targetPaymentDate)) {
            setLoadingRefund(false)
            setShowWarningAlert2(true)
          // void message.warning(`Refund can only be triggered for payments starting from ${modifiedTargetPaymentDate}. Any Assistance Please contact us on iecms-support@minijust.gov.rw`)
            return
        }
    const validateOtpExpirationPayload = {
      transaction_reference: transactionReference,
      status: 'PENDING'
    }
            if (!declarationChecked) {
                setLoadingRefund(false)
                void message.warning('Please confirm the declaration part to be able to submit your Request.')
                return
            }
            // Here you should validate all form fields, and if they are valid, set isFormValidated to true
            form
              .validateFields()
              .then(() => {
                  setIsFormValidated(true)
              })
              .catch(() => {
                  setIsFormValidated(false)
                  setLoadingRefund(false)
              })
            void InstitutionUserService.validateRequestWhenOtpExpired(validateOtpExpirationPayload)
            const payload = {
                RefundRequest: {
                    bank_code: values.bank_code,
                    transaction_reference: values.transaction_reference,
                    debit_account_number: '000400696575429',
                    debit_account_name: 'MINIJUST AUCTION FUNDS MAF',
                    beneficiary_account_name: values.beneficiary_bank_account_name,
                    beneficiary_bank_name: values.beneficiary_bank_name,
                    beneficiary_account_number: values.beneficiary_account_number,
                    amount: parseInt(values.refundable_bid_security_amount.replace(/,/g, '')),
                    transaction_currency: values.transaction_currency,
                    reason: `Refund of ${values.amount} for ${values.beneficiary_bank_account_name} ${values.bidder_national_id_number}, ${values.phone_number} ${values.execution_case_number} with ${values.property_id}`,
                    // reason: `Execution Case Number: ${values.execution_case_number} - Refund Request of ${values.amount} Rwf for ${values.beneficiary_account_name}:(ID Number:${values.bidder_national_id_number}, Phone Number: ${values.phone_number}) with Property ID:${values.property_id}`,
                    status: 'PENDING',
                    bidder_id: values.bidder_id,
                    bid_security_id: values.bid_security_id,
                    phone_number: values.phone_number
                }
            }
            const payloadOTP = {
                transaction_reference: values.transaction_reference,
                status: 'PENDING'
            }
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            // const formPropertyId = form.getFieldValue('property_id')
            // Call the API to validate auction details
            void InstitutionUserService.validateBidderForRefund(ref_number)
              .then((response: any) => {
                  // alert(JSON.stringify(response))
                  if (response.data.code === 200) {
                      InstitutionUserService.TriggerRefundRequest(payload, token)
                        .then((res: any) => {
                            if (res.status === 201) {
                                setShowOTPForm(true)
                                setLoadingRefund(false)
                                setIsFormValidated(true)
                                setTimeout(() => {
                                    setLoadingRefund(false)
                                    // void message.success('Refund Request Submitted. Waiting for being Refunded...')
                                }, 5000)
                                InstitutionUserService.createOtpCode(payloadOTP)
                                  .then((respo: any) => {
                                      if (respo.status === 200) { /* empty */ }
                                  })
                                  // eslint-disable-next-line @typescript-eslint/no-shadow
                                  .catch((error: any) => {
                                      if (error.respo.status === 400) {
                                          void message.error('fail')
                                      }
                                  })
                            }
                            form.resetFields()
                        })
                        .catch((err: any) => {
                            if (err?.response?.status === 400) {
                                setLoadingRefund(false)
                                setShowOTPForm(false)
                                setShowWarningAlert(true)
                                // void message.info(`Refund Request already exists with the same Transaction Reference Number: ${values.transaction_reference}`)
                            }
                        })
                  } else {
                      setLoadingRefund(false)
                      void message.info('You Are Not allowed. Refund Request cannot Be Processed.')
                  }
              })
    }
  return (
        <div className="w-full">
            <div className="navbar pay-bot-border navbar-background">
                <div className="flex">
                    <div className="nav-logo">
                        <img src={minijust_logo} alt="Logo" />
                    </div>
                    <div className="nav-content text-sm md:text-2xl">
                        <div className="nav-content-header">MINIJUST - IECMS</div>
                        <div>Bid Security Online Payment and Refund</div>
                    </div>
                </div>
                <div className="items-center font-semibold text-xs">
                    <GlobalOutlined className="nav-globe" />
                    <div>ENGLISH</div>
                </div>
            </div>

            <div className="scrollableContent">
              {showForm && fetchedBankCode !== null && (
                <Form name="normal_login" layout="vertical" className="login-form p-10"
                      initialValues={{ remember: true }} autoComplete="off" onFinish={onFinish}
                      form={form}>
                    <Space direction="vertical" size={5} className=" w-full content-background">
                        <Card className="card-border h-full ">
                            <div
                              className="font-extrabold text-xl flex text-center
            items-center justify-center leading-10 text-gray-500 pb-2"
                            >
                                Please fill the form below for requesting refund
                            </div>

                            <div
                              className="green-color font-extrabold text-xl flex text-center
            items-center justify-center py-5"
                            >
                                Refund Request Details
                            </div>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={4} xl={4}></Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      label="Bidder's National ID Number"
                                      name="bidder_national_id_number"
                                      rules={[
                                          {
                                              required: true,
                                              message: "Please enter Bidder's National ID Number"
                                          }
                                      ]}
                                    >
                                        <Input
                                          maxLength={16}
                                          placeholder="Enter Bidder's National ID Number" disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      label="Phone Number"
                                      name="phone_number"
                                      rules={[{
                                          required: true,
                                          message: 'Please enter Phone Number'
                                      },
                                          {
                                              message: 'Phone number should be either 4 digits or 10 digits.',
                                              pattern: PHONE_PATTERN
                                          }]}
                                    >
                                        <Input type='tel' placeholder="Enter Phone Number"
                                               disabled/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={4} xl={4}></Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={4} xl={4}></Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      label="Property ID"
                                      name="property_id"
                                      rules={[
                                          {
                                              required: true,
                                              message: 'Please enter Property ID'
                                          }
                                      ]}
                                    >
                                        <Input placeholder="Enter Property ID" disabled/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      label="Execution Case Number"
                                      name="execution_case_number"
                                      rules={[{
                                          required: true,
                                          message: 'Please enter Execution Case Number'
                                      }]}
                                    >
                                        <Input placeholder="Execution Case Number" disabled/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={4} xl={4}></Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={4} xl={4}></Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      label="Bid Reference Number"
                                      name="transaction_reference"
                                      rules={[{
                                          required: true,
                                          message: 'Please enter transaction reference'
                                      }]}
                                    >
                                        <Input placeholder="Bid Reference Number" disabled/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      label="Beneficiary Name"
                                      name="beneficiary_bank_account_name"
                                      rules={[{
                                          required: true,
                                          message: 'Please enter Beneficial Name'
                                      }]}
                                    >
                                        <Input placeholder="Beneficiary Account Name" disabled/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={4} xl={4}></Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={4} xl={4}></Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      label="Deposit Bid Security Amount"
                                      name="amount"
                                      rules={[{
                                          required: true,
                                          message: 'Please enter Amount'
                                      }]}
                                    >
                                        <Input type='text' placeholder="Amount" disabled/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      label="Currency"
                                      name="transaction_currency"
                                      rules={[{
                                          required: true,
                                          message: 'Please provide the transaction currency'
                                      }]}
                                    >
                                        <Input
                                          maxLength={24}
                                          placeholder="RWF" disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={4} xl={4}></Col>
                            </Row>

                          <Row gutter={[16, 16]}>
                            <Col xs={24} md={4} xl={4}></Col>
                            <Col xs={24} md={8} xl={8}>
                              <Form.Item
                                label="Bid Security Payment Date"
                                name="payment_date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Payment Date not Available'
                                  }
                                ]}
                              >
                                <Input placeholder="Bid Payment Date" disabled/>
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8} xl={8}>
                              <Form.Item
                                label="Refundable Bid Security Amount"
                                name="refundable_bid_security_amount"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Refundable Bid Security Amount not Available'
                                  }
                                ]}
                              >
                                <Input placeholder="Refundable Bid Security Amount" disabled />
                              </Form.Item>
                            </Col>
                          </Row>

                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={4} xl={4}></Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      label="Bank Account Number"
                                      name="beneficiary_account_number"
                                      rules={[
                                          {
                                              required: true,
                                              message: 'Please enter Bank Account Number'
                                          },
                                          {
                                              pattern: /^[0-9]+$/,
                                              message: 'Please enter only numbers for Bank Account Number'
                                          }
                                      ]}
                                    >
                                        <Input
                                          maxLength={24}
                                          placeholder="Enter Bidder's Bank Account Number" disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      label="Bank Name"
                                      name="beneficiary_bank_name"
                                      rules={[
                                          {
                                              required: true,
                                              message: "Please enter Bidder's Bank Name"
                                          }
                                      ]}
                                    >
                                        <Input placeholder="Beneficiary Account Name" disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={4} xl={4}></Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      label="Bank Account Name"
                                      name="beneficiary_bank_account_name"
                                      rules={[{
                                          required: true,
                                          message: 'Please enter Bank Account Name'
                                      }]}
                                    >
                                        <Input type='text' placeholder="Enter Bank Account Name"
                                               disabled/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={4} xl={4}>
                                    <Form.Item
                                      // label="Bank Code"
                                      name="bank_code"
                                      rules={[
                                          {
                                              required: true,
                                              message: 'Bank Code not Available'
                                          }
                                      ]}
                                    >
                                        <Input className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden"
                                          disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={4} xl={4}></Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      // label=" Bidder "
                                      name="bidder_id"
                                    >
                                        <Input
                                          className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden"
                                          disabled/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8} xl={8}>
                                    <Form.Item
                                      // label="Bid security"
                                      name="bid_security_id"
                                    >
                                        <Input type='text'
                                               className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default"
                                               disabled/>
                                    </Form.Item>
                                    {loadingRefund && <Spin size="large"/>}
                                </Col>
                                <Col xs={24} md={4} xl={4}></Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={4} xl={4}></Col>
                                <Col xs={24} md={16} xl={16}>
                                    <Form.Item name="declaration" rules={[{ required: false }]}>
                                        <Checkbox onChange={(e) => {
                                            setDeclarationChecked(e.target.checked)
                                        }}>
                                            I, <b>{fullName}</b>, hereby declare that the provided
                                            information about <b>Account Number, Bank Account Name
                                            and Bank Name</b> are true, accurate and complete.
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={4} xl={4}></Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={4} xl={4}></Col>
                                <Col xs={24} md={16} xl={16}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit"
                                                disabled={loadingRefund || !declarationChecked}>
                                            {isEdit ? 'Update' : 'Continue'}
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={4} xl={4}></Col>
                            </Row>
                        </Card>
                    </Space>
                </Form>
              )}
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col xs={24} md={24} xl={24}>
                  {showSuccessAlert && (
                    <Modal
                      visible={showSuccessAlert}
                      onCancel={() => {
                        setShowSuccessAlert(false)
                      }}
                      footer={null}
                      centered
                    >
                      <Alert
                        message="Informational Notes"
                        /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                        description="Refund Request Submitted. Waiting to be Refunded..."
                        type="info"
                        showIcon
                        style={{
                          width: '100%'
                        }} // Set alert background color
                        onClose={() => {
                          setShowSuccessAlert(false)
                        }}
                      />
                    </Modal>
                  )}
                </Col>
              </Row>
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col xs={24} md={24} xl={24}>
                  {showWarningAlert && (
                    <Modal
                      visible={showWarningAlert}
                      onCancel={() => {
                        setShowWarningAlert(false)
                      }}
                      footer={null}
                      centered
                    >
                      <Alert
                        message="Informational Notes"
                        /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                        description={`Refund Request already exists with the same Transaction Reference Number: ${transactionReference}`}
                        type="info"
                        showIcon
                        style={{
                          width: '100%'
                        }} // Set alert background color
                        onClose={() => {
                          setShowWarningAlert(false)
                        }}
                      />
                    </Modal>
                  )}
                </Col>
              </Row>
              <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col xs={24} md={24} xl={24}>
                  {showWarningAlert2 && (
                    <Modal
                      visible={showWarningAlert2}
                      onCancel={() => {
                        setShowWarningAlert2(false)
                      }}
                      footer={null}
                      centered
                    >
                      <Alert
                        message="Informational Notes"
                        /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                        description={`Refund can only be triggered for payments starting from ${targetPaymentDateValue}. Any assistance, please contact us at iecms-support@minijust.gov.rw`}
                        type="error"
                        showIcon
                        style={{
                          width: '100%'
                        }} // Set alert background color
                        onClose={() => {
                          setShowWarningAlert2(false)
                        }}
                      />
                    </Modal>
                  )}
                </Col>
              </Row>
                {/* Step 3: Render OTP form conditionally */}
                <Modal
                  title="Enter the provided code to submit your Request"
                  visible={showOTPForm && isFormSubmitted && isFormValidated && declarationChecked}
                  onCancel={handleOTPFormCancel}
                  footer={[
                      <Button key="cancel" onClick={handleOTPFormCancel}>
                          Cancel
                      </Button>,
                      <Button key="submit" type="primary" onClick={handleOTPSubmit}>
                          Submit
                      </Button>
                  ]}
                  className="custom-modal"
                  keyboard={false}
                  maskClosable={false}
                >
                    <Form.Item
                      name="otp"
                    >
                        {/* OTP input field */}
                        <Input placeholder="Enter Code" value={otpValue}
                               onChange={handleInputChange}/>
                    </Form.Item>
                    <p className="msgOTP">

                        We just sent you an SMS on this phone number:
                        ********{phone_number.slice(-2)} with
                        an OTP
                        (One Time Password). Enter the OTP in the form above to confirm your
                        request.{' '}
                    </p>
                    <br/>
                    <p>
                        The OTP will expire in 5 minutes
                        <code className="font-semibold ml-2">
                            {moment(countdown).format('mm:ss')}</code>.{' '}
                        {countdown === 0 && <Button type="link">Click here to resend SMS.</Button>}
                    </p>
                </Modal>

              <Modal
                title="Notification"
                visible={showBankCodeModal}
                onCancel={() => {
                  setShowBankCodeModal(false)
                  setShowCancelMessage(true)
                }}
                footer={null}
                centered
              >
                {isVisible && (
                  <p>
                    <strong>Please wait while fetching...</strong>
                  </p>
                )}
                {showHello &&
                  <p>The bank details are missing. You are not eligible to be refunded.</p>}
              </Modal>
              <Modal
                title="Notification"
                visible={showInvalidReferenceNumberModal}
                onCancel={() => {
                  setShowInvalidReferenceNumberModal(false)
                  setShowCancelMessage(true)
                }}
                footer={null}
                centered
              >
                {isVisible && (
                  <p>
                    <strong>Please wait while fetching...</strong>
                  </p>
                )}
                {showHello &&
                  <p>Invalid Reference Number. You are not eligible to be refunded.</p>}
              </Modal>
              <Modal
                title={fetchedBankCode === '040' ? 'Bid Security Refund Amount' : 'Bank Transfer Charges'}
                visible={showBankTransferChargeModal && fetchedBankCode !== null && form.getFieldValue('beneficiary_bank_account_name') !== null}
                onCancel={() => {
                  setShowBankTransferChargeModal(false)
                  setShowCancelMessage(true)
                }}
                footer={[
                  <Button key="cancel" onClick={() => {
                    setShowBankTransferChargeModal(false)
                    setShowCancelMessage(true)
                  }}>
                    Cancel
                  </Button>,
                  <Button type="primary" key="ok" onClick={handleAgree}>
                    I Agree
                  </Button>
                ]}
                keyboard={false}
                maskClosable={false}
                className="custom-modal"
              >
                <p>
                  {/* eslint-disable-next-line max-len */}
                  You will be charged <strong>{fetchedBankCode !== '040' ? '1,000 Rwf (BK to other Banks).' : '0 Rwf (BK to BK)'}</strong>.
                </p>
                <p>
                  Total bid Security Paid: <strong>{parseInt(String(paymentAmount)).toLocaleString()} Rwf </strong>
                </p>
                {fullName != null && (
                  <p>
                    Amount Received in Your Account after Transfer: <strong>
                    {parseInt(String(Number(paymentAmount) - (fetchedBankCode !== '040' ? 1000 : 0))).toLocaleString()} Rwf
                  </strong>
                  </p>
                )}
              </Modal>

              {showCancelMessage && (
                <div className="cancel-message">
                  <div className="separator"></div>
                  <p>
                    <h3>Thank you for using our services.</h3>
                  </p>
                  <div className="separator"></div>
                  <p>For any other support, please contact us on
                     <span><strong> iecms-support@minijust.gov.rw</strong></span>.</p>
                </div>
              )}
            </div>
        </div>
  )
}

export default MakeRefundRequest
