import React, { useState } from 'react'
import {
  Form,
  Input,
  Card,
  Row,
  Col,
  Space,
  Modal,
  Button,
  message,
  Spin,
  Checkbox,
  Select,
  Alert
} from 'antd'
import InstitutionUserService from '../../services/InstitutionUserService'
import minijust_logo from '../../assets/img/minijust_logo.png'
import { GlobalOutlined } from '@ant-design/icons'
import { PHONE_PATTERN } from '../../constants/general'
import moment from 'moment'

// Define the component
const RequestPaymentClaim: React.FC = () => {
  const [form] = Form.useForm()
  const [loadingRefund, setLoadingRefund] = useState(false)
  const [declarationChecked, setDeclarationChecked] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [biddersStatus, setBiddersStatus] = useState(false)
  const [showOTPForm, setShowOTPForm] = useState(false)
  const [phone_number, setPhoneNumber] = useState('')
  const [showWarningAlert, setShowWarningAlert] = useState(false)
  const [showWarningAlert2, setShowWarningAlert2] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showCancelMessage, setShowCancelMessage] = useState(false)
  const [targetPaymentDateValue, setTargetPaymentDateValue] = useState(false)
  const [paymentDate, setPaymentDate] = useState('')
  const [token, setToken] = useState('')
  const [bidderReferenceNumber, setBidderReferenceNumber] = useState('')
  const [generatedLink, setGeneratedLink] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleGenerateLink = () => {
    // const link = `http://197.243.104.36/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    // const link = `http://localhost:3000/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    const link = `https://payment.iecms.gov.rw/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    setGeneratedLink(link)
    alert(`Generated Link: ${link}`)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleContinue = () => {
    if (declarationChecked) {
      setShowOTPForm(true)
    }
  }
  const handleReferenceChange = async (value: string): Promise<void> => {
    setBidderReferenceNumber(value)
    if (value !== '') {
      setLoadingRefund(true)
      try {
        const paymentData = await InstitutionUserService.loadBidsDetailsForRefund(value)
        if (paymentData.status === 200) {
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-shadow
          const encryptReferenceNumber = (value: any) => {
            // Generate a random string to add
            const randomString = Math.random().toString(36).substring(2, 8) // Adjust length as needed
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const combinedValue = `${randomString}-${value}-${randomString}`
            const utf8Value = unescape(encodeURIComponent(combinedValue))
            return btoa(utf8Value)
          }
          const encryptedReference = encryptReferenceNumber(paymentData.data?.ref_number)
          // const generate_link = `http://197.243.104.36/refund-request/requestBidSecurityFees/${encryptedReference}`
          // const generate_link = `http://localhost:3000/refund-request/requestBidSecurityFees/${encryptedReference}`
          const generate_link = `https://payment.iecms.gov.rw/refund-request/requestBidSecurityFees/${encryptedReference}`
          form.setFieldsValue({
            bidder_id: paymentData.data?.bid?.bidder?.bidder_id,
            bid_security_id: paymentData.data?.payment?.bid_security_id,
            amount: paymentData.data?.payment?.amount,
            transaction_currency: paymentData.data?.payment?.currency,
            transaction_reference: paymentData.data?.ref_number,
            phone_number: paymentData.data?.bid?.bidder?.phone_number,
            bidder_national_id_number: paymentData.data?.bid?.bidder?.nid,
            property_id: paymentData.data?.bid?.auction?.property_id,
            execution_case_number: paymentData.data?.bid?.auction?.execution_case_no,
            fullname: paymentData.data?.bid?.bidder?.full_name,
            email: paymentData.data?.bid?.bidder?.email,
            payment_date: paymentData.data?.payment?.payment_date,
            generate_link
          })
          setPaymentDate(paymentData.data?.payment?.payment_date)
          setBiddersStatus(paymentData.status)
        } else {
          throw new Error('No data found for the given reference number.')
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred.'
        Modal.error({
          title: 'Error',
          content: errorMessage
        })
      } finally {
        setLoadingRefund(false)
      }
    }
  }
  // Handle search button click
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSearchClick = () => {
    const referenceNumber = form.getFieldValue('bid_reference_number')
    void handleReferenceChange(referenceNumber)
    setShowForm(true)
    setBidderReferenceNumber(form.getFieldValue('bid_reference_number'))
    // const link = `http://197.243.104.36/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    // const link = `http://localhost:3000/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    const link = `https://payment.iecms.gov.rw/refund-request/bidSecurityReference/${bidderReferenceNumber}`
    setGeneratedLink(link)
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const onFinish = async (values: any): void => {
    const paymentDateTime = moment(paymentDate, 'YYYY-MM-DD HH:mm:ss')
    const targetPaymentDate = moment('2022-07-25', 'YYYY-MM-DD')
    // Check if payment date is valid for a refund
    if (!paymentDateTime.isSameOrAfter(targetPaymentDate)) {
      setLoadingRefund(false)
      setShowWarningAlert2(true)
      // Optionally show a warning message
      return
    }
    // Construct the payload for the refund request
    const payload = {
      RefundRequest: {
        transaction_reference: values.transaction_reference,
        debit_account_number: '000400696575429',
        debit_account_name: 'MINIJUST AUCTION FUNDS MAF',
        transaction_currency: values.transaction_currency,
        reason: 'Did not participate in the bidding process but was paid.',
        status: 'PENDING',
        bidder_id: values.bidder_id,
        bid_security_id: values.bid_security_id,
        phone_number: values.phone_number,
        email: form.getFieldValue('email'),
        link: form.getFieldValue('generate_link')
      }
    }
    try {
      const body = {
        transaction_reference: bidderReferenceNumber
      }
      const validateRequest = await InstitutionUserService.validateRefundRequest(body)
      if (validateRequest === null || validateRequest.status === 200) {
        void message.info('Request Exists')
        return
      }
    } catch (error) {
      // void message.error('An error occurred while sending the refund request. Please try again later.')
      // Send the refund request
      void message.error('Refund request submitted successfully. Waiting for Bidder to Request refund...')
      const res = await InstitutionUserService.SendRefundRequestLink(payload, token)
      if (res.status === 201) {
        void message.success('Refund request submitted successfully. Waiting for Bidder to Request refund...')
      } else {
        void message.error('Failed to submit the refund request. Please try again.')
      }
    } finally {
      setLoadingRefund(false)
    }
  }

  return (
    <div className="w-full">
      <div
        style={{
          textAlign: 'left',
          marginTop: '20px',
          marginLeft: '70px',
          fontFamily: 'Arial, sans-serif',
          fontSize: '16px',
          backgroundColor: '#f8f9fa', // Background color for the entire division
          padding: '10px', // Add some padding for better spacing
          borderRadius: '5px' // Optional: rounded corners
        }}
      >
  <span style={{
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '10px'
  }}>
    <a
      href="/update-claim-refund-request"
      style={{
        textDecoration: 'none',
        color: '#37762f',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <i className="fas fa-sync" style={{ marginRight: '5px' }}></i>
      Update Claim Refund Request
    </a>
  </span>
        <span style={{
          display: 'inline-flex',
          alignItems: 'center'
        }}>
    <span style={{ margin: '0 10px' }}>||</span>
    <a
      href="/view-list"
      style={{
        textDecoration: 'none',
        color: '#37762f',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <i className="fas fa-list" style={{ marginRight: '5px' }}></i>
      View Claim Requests List.
    </a>
  </span>
      </div>

      <div className="request-ref">
        <Form name="normal_login" layout="vertical" className="login-form p-10" autoComplete="off"
              form={form}
              initialValues={{ remember: true }} onFinish={onFinish}
        >
          <Card className="bg-slate-100">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={4} xl={4}></Col>
              <Col xs={24} md={8} xl={8}>
                <Form.Item
                  label="Bid Reference Number"
                  name="bid_reference_number"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Bid Reference Number'
                    },
                    {
                      pattern: /^[A-Za-z\d!@#$%^&*()_+={}[\]:;"'<>,.?/~`\\|-]{7}$/,
                      message: 'Bid Reference Number must be exactly 7 characters long and can include letters, digits, and special characters.'
                    }
                  ]}
                >
                  <Input type="tel" placeholder="Enter Bid Reference Number"/>
                </Form.Item>
              </Col>
              <Col xs={24} md={8} xl={8}>

              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={4} xl={4}></Col>
              <Col xs={24} md={8} xl={8}>

              </Col>
              <Col xs={24} md={8} xl={8}>
                <Button
                  type="primary"
                  onClick={handleSearchClick}
                  loading={loadingRefund}
                >
                  Search
                </Button>
              </Col>
            </Row>
            <div className="spacing-row">
              {showForm && biddersStatus && (
                <p>View Bidders Details and Generate Link for Bidder to request 5% of bid Security.
                </p>
              )}</div>
            {showForm && biddersStatus && (
              <div>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Phone Number"
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Phone Number'
                        },
                        {
                          pattern: PHONE_PATTERN,
                          message: 'Phone number should be 10 digits.'
                        }
                      ]}
                    >
                      <Input
                        type="tel"
                        placeholder="Enter Phone Number"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="National ID"
                      name="bidder_national_id_number"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Your National ID'
                        },
                        {
                          message: 'National ID should be 16 digits.'
                        }
                      ]}
                    >
                      <Input
                        placeholder="National ID is missing"
                        maxLength={16}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Property ID"
                      name="property_id"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Property ID'
                        }
                      ]}
                    >
                      <Input placeholder="Enter Property ID" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Execution Case Number"
                      name="execution_case_number"
                      rules={[{
                        required: true,
                        message: 'Please enter Execution Case Number'
                      }]}
                    >
                      <Input placeholder="Execution Case Number" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bid Reference Number"
                      name="transaction_reference"
                      rules={[{
                        required: true,
                        message: 'Please enter transaction reference'
                      }]}
                    >
                      <Input placeholder="Bid Reference Number" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bidder's Names"
                      name="fullname"
                      rules={[{
                        required: true,
                        message: 'Please enter Bidders Name'
                      }]}
                    >
                      <Input placeholder="Bidders Name" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Deposit Bid Security Amount"
                      name="amount"
                      rules={[{
                        required: true,
                        message: 'Please enter Amount'
                      }]}
                    >
                      <Input type='text' placeholder="Amount" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Currency"
                      name="transaction_currency"
                      rules={[{
                        required: true,
                        message: 'Please provide the transaction currency'
                      }]}
                    >
                      <Input
                        maxLength={24}
                        placeholder="RWF" disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bid Security Payment Date"
                      name="payment_date"
                      rules={[
                        {
                          required: true,
                          message: 'Payment Date not Available'
                        }
                      ]}
                    >
                      <Input placeholder="Bid Payment Date" disabled/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Email not Available'
                        }
                      ]}
                    >
                      <Input placeholder="Bidder Email" disabled/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={12} xl={12}>
                    <Form.Item
                      label="Generate link for bidders"
                      name="generate_link"
                      rules={[
                        {
                          required: true,
                          message: 'Generated link Is Required'
                        }
                      ]}
                    >
                      <Input placeholder="Generated Link" readOnly disabled/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      // label=" Bidder "
                      name="bidder_id"
                    >
                      {/* <Input */}
                      {/*   type="text" */}
                      {/*   className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden" */}
                      {/*   disabled/> */}
                      <Input
                        type='text'
                        className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden"
                        disabled
                        style={{ visibility: 'hidden', height: '0', width: '0', position: 'absolute' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      // label="Bid security"
                      name="bid_security_id"
                    >
                      {/* <Input type='text' */}
                      {/*        className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden" */}
                      {/*        disabled/> */}
                      <Input
                        type='text'
                        className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden"
                        disabled
                        style={{ visibility: 'hidden', height: '0', width: '0', position: 'absolute' }}
                      />

                    </Form.Item>
                    {loadingRefund && <Spin size="large"/>}
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={16} xl={16}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={loadingRefund}
                        onClick={handleContinue}
                      >
                        {isEdit ? 'Update' : 'Submit'}
                      </Button>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={4} xl={4}></Col>
                </Row>
              </div>
            )}

          </Card>
        </Form>
      </div>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col xs={24} md={24} xl={24}>
          {showSuccessAlert && (
            <Modal
              visible={showSuccessAlert}
              onCancel={() => {
                setShowSuccessAlert(false)
              }}
              footer={null}
              centered
            >
              <Alert
                message="Informational Notes"
                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                description="Refund Request Submitted. Waiting to be Refunded..."
                type="info"
                showIcon
                style={{
                  width: '100%'
                }} // Set alert background color
                onClose={() => {
                  setShowSuccessAlert(false)
                }}
              />
            </Modal>
          )}
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col xs={24} md={24} xl={24}>
          {showWarningAlert && (
            <Modal
              visible={showWarningAlert}
              onCancel={() => {
                setShowWarningAlert(false)
              }}
              footer={null}
              centered
            >
              <Alert
                message="Informational Notes"
                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                description={'Refund Request already exists with the same Transaction Reference Number:'}
                type="info"
                showIcon
                style={{
                  width: '100%'
                }} // Set alert background color
                onClose={() => {
                  setShowWarningAlert(false)
                }}
              />
            </Modal>
          )}
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col xs={24} md={24} xl={24}>
          {showWarningAlert2 && (
            <Modal
              visible={showWarningAlert2}
              onCancel={() => {
                setShowWarningAlert2(false)
              }}
              footer={null}
              centered
            >
              <Alert
                message="Informational Notes"
                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                description={`Refund can only be triggered for payments starting from ${targetPaymentDateValue}. Any assistance, please contact us at iecms-support@minijust.gov.rw`}
                type="error"
                showIcon
                style={{
                  width: '100%'
                }} // Set alert background color
                onClose={() => {
                  setShowWarningAlert2(false)
                }}
              />
            </Modal>
          )}
        </Col>
      </Row>
      {showCancelMessage && (
        <div className="cancel-message">
          <div className="separator"></div>
          <p>
            <h3>Thank you for using our services.</h3>
          </p>
          <div className="separator"></div>
          <p>For any other support, please contact us on
            <span><strong> iecms-support@minijust.gov.rw</strong></span>.</p>
        </div>
      )}
    </div>
  )
}

export default RequestPaymentClaim
