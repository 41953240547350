import React, { lazy, Suspense, useEffect, useState } from 'react'
import './App.css'
import Dashboard from './components/dashboard/Dashboard'
import FinanceDashboard from './components/dashboard/FinanceDashboard'
import InstitutionDashboard from './components/dashboard/InstitutionDashboard'
import PrivateRoute from './components/layout/PriviteRoute'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import logo from './logo.svg'
import ChooseChannel from './components/payments/ChooseChannel'
import OTP from './components/login/OTP'
import BiddersRefundRequest from './components/refund/BiddersRefundRequest'
import MakeRefundRequest from './components/refund/MakeRefundRequest'
import ViewRefundRequest from './components/refund/viewRefundRequest'
import BiddersRefundRequestStatus from './components/refund/BiddersRefundRequestStatus'
import BidSecuritiesTable from './components/bidguarantees/BidSecuritiesTable'
import ViewBidSecurities from './components/bidguarantees/ViewBidSecurities'
import DigitalPaymentList from './components/bidguarantees/DigitalPaymentList'
import CertifiedCheckList from './components/bidguarantees/CertifiedCheckList'
import BidGuarantiesList from './components/bidguarantees/BidGuarantiesList'
import ViewCertifiedCheck from './components/bidguarantees/ViewCertifiedCheck'
import PendingBiddersRefundRequest from './components/refund/PendingBidderRefundRequest'
import BiddersEligibleForRefund from './components/refund/BiddersEligibleForRefund'
import BaillifRefundRequest from './components/refund/BaillifRefundRequest'
import ViewBailiffRefundRequest from './components/refund/ViewBailiffRefundRequest'
import RefundRequestList from './components/refund/RefundRequestList'
import CreateInstitutionAccount from './components/institution/CreateInstitutionAccount'
import Notification from './components/usersaccounts/Notification'
import SystemAudit from './components/Audit/SystemAudit'
import PaymentProof from './components/payments/PaymentProof'
import BidSecurityPaymentReport from './components/payments/BidSecurityPaymentReport'
import RefundRequestReport from './components/refund/RefundRequestReport'
import BankTransferUpdateStatus from './components/refund/BankTransferUpdateStatus'
import EditRefundRequestStatus from './components/refund/EditRefundRequestStatus'
import RequestBidSecurityFees from './components/refund/RequestBidSecurityFees'
import RequestPaymentClaim from './components/refund/RequestPaymentClaim'
import ViewClaimList from './components/refund/ViewClaimList'
import UpdateClaimRefundRequest from './components/refund/UpdateClaimRefundRequest'
// import UserContext from './context/UserContext'
const Login = lazy(async () => await import('../src/components/login/Login'))
const Layout = lazy(async () => await import('../src/components/layout/Layout'))
const Institution = lazy(
  async () => await import('../src/components/institution/CreateInstitution')
)
const ViewInstitution = lazy(
  async () => await import('../src/components/institution/ViewInstitution')
)
const InstitutionTable = lazy(
  async () => await import('../src/components/institution/InstitutionTable')
)
const BidGurantee = lazy(
  async () => await import('../src/components/bidguarantees/CreateBidGuarantee')
)
const BidGuaranteeTable = lazy(
  async () => await import('../src/components/bidguarantees/BidGuaranteeTable')
)
const UsersAccounts = lazy(
  async () => await import('../src/components/usersaccounts/CreateUsersAccounts')
)
const UsersAccountsTable = lazy(
  async () => await import('../src/components/usersaccounts/UsersAccountsTable')
)
const ViewBidGuarantee = lazy(
  async () => await import('../src/components/bidguarantees/ViewBidGuarantee')
)
const BidderTable = lazy(async () => await import('../src/components/bidders/BidderTable'))
const PaymentsTable = lazy(async () => await import('../src/components/payments/PaymentsTable'))
const ViewPayment = lazy(async () => await import('../src/components/payments/ViewPayment'))
const MakePayment = lazy(async () => await import('../src/components/payments/MakePayment'))
const PaymentReceipt = lazy(async () => await import('../src/components/payments/PaymentReceipt'))

const ViewUserAccount = lazy(
  async () => await import('../src/components/usersaccounts/ViewUserAccounts')
)
const ViewBidder = lazy(async () => await import('../src/components/bidders/ViewBidder'))
const CreateUserProfile = lazy(
  async () => await import('../src/components/userprofile/CreateUserProfile')
)
const Page404 = lazy(async () => await import('../src/components/pages/404'))
const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#37762F' }} spin />
const App: React.FC = () => {
  const [userData, setUserData]: any = useState({})
  // const userInfo = UserContext.userInfo
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}')
    setUserData(userInfo)
  }, []) // userData

  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div
              className="h-full w-full relative align-middle text-center
          justify-center items-center marg"
            >
              <Spin indicator={antIcon} />
              <div className="flex align-middle text-center justify-center">
                <div className="flex items-center">
                  <img className="h-12 w-auto rounded-full py-0.5" src={logo} alt="Logo" />
                </div>
                <div className="font-bold green-color text-base pt-1 pl-2 leading-5">
                  <div className="">MINIJUST - IECMS</div>
                  <div>Bid Guarantee MIS</div>
                </div>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/verify" element={<OTP />} />
            <Route path="/payment" element={<MakePayment />} />
            <Route path="/refund-request/bidSecurityReference/:ref_number" element={< MakeRefundRequest/>}/>
            <Route path= "/refund-request/requestBidSecurityFees/:ref_number" element={< RequestBidSecurityFees/>}/>
            <Route path="/bailiff/baillif-refund-request/bidSecurityReference/:ref_number" element={<BaillifRefundRequest/>}/>
            <Route path="/choose-payment/:mcode/:pcode" element={<ChooseChannel />} />
            <Route path="/receipt/:bill_id" element={<PaymentReceipt />} />
            {/* <Route path="/" element={<Navigate replace to="/misbid-login" />} /> */}
            <Route element={<Layout />}>
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/institution-dashboard" element={<InstitutionDashboard />} />
                <Route path="/finance-dashboard" element={<FinanceDashboard />} />
                {userData?.role === 'SYSTEM ADMIN'
                  ? (
                  <>
                    <Route path="/bidder-list" element={<BidderTable />} />
                    <Route path="/institutions-list" element={<InstitutionTable />} />
                    <Route path="/create-institution" element={<Institution />} />
                    <Route path="/update-credit-account" element={<CreateInstitutionAccount/>}/>
                    <Route path="/notification" element={<Notification/>}/>
                    <Route path="/system-audit" element={<SystemAudit/>}/>
                    <Route path="/payment_proof" element={<PaymentProof/>}/>
                    <Route path="/bid-security-payment-Report" element={<BidSecurityPaymentReport/>}/>
                    <Route path="/refund-request-report" element={<RefundRequestReport/>}/>
                    <Route path="/view-institution" element={<ViewInstitution />} />
                    <Route path="/create-users-accounts" element={<UsersAccounts />} />
                    <Route path="/payment-list" element={<PaymentsTable />} />
                    <Route path="/bid-guarantee-list" element={<BidGuaranteeTable />} />
                    <Route path="/view-bid-guarantee" element={<ViewBidGuarantee />} />
                    <Route path="/refund-request-list" element={<RefundRequestList/>} />
                    <Route path="/view-refund-request" element={<ViewRefundRequest/>}/>
                    <Route path="/request-payment-claim" element={<RequestPaymentClaim/>}/>
                    <Route path="/update-claim-refund-request" element={<UpdateClaimRefundRequest/>}/>
                    <Route path="/view-list" element={<ViewClaimList/>}/>
                  </>
                    )
                  : (
                      ''
                    )}
                {userData?.role === 'INSTITUTION ADMIN' || userData?.role === 'INSTITUTION USER'
                  ? (
                  <>
                    <Route path="/create-bid-guarantee" element={<BidGurantee />} />
                    {userData?.role !== 'INSTITUTION USER'
                      ? (
                      <Route path="/create-users-accounts" element={<UsersAccounts />} />
                        )
                      : (
                          ''
                        )}
                  </>
                    )
                  : (
                      ''
                    )}
                {userData?.role === 'MINIJUST FINANCE'
                  ? (
                  <>
                    <Route path="/payment-list" element={<PaymentsTable />} />
                    <Route path="/bid-guarantee-list" element={<BidGuaranteeTable />} />
                    <Route path="/bid-securities-list" element={<BidSecuritiesTable/>} />
                    <Route path="/digital-payments-list" element={<DigitalPaymentList/>}/>
                    <Route path="/certified-check-list" element={<CertifiedCheckList/>}/>
                    <Route path="/bid-guaranties-list" element={<BidGuarantiesList/>}/>
                    <Route path="/bidders-refund-request" element={<BiddersRefundRequest/>}/>
                    <Route path="/view-refund-request" element={<ViewRefundRequest/>}/>
                    <Route path="/bidder-refund-request-status" element={<BiddersRefundRequestStatus/>}/>
                    <Route path="/view-bid-securities" element={<ViewBidSecurities />} />
                    <Route path="/view-bid-guarantees" element={< ViewBidGuarantee/>} />
                    <Route path="/bank-transfer-update-status" element={<BankTransferUpdateStatus />} />
                    <Route path="/view-certified-check-details" element={<ViewCertifiedCheck/>} />
                    <Route path="/bidder-refund-request" element={<BiddersRefundRequest />} />
                    <Route path="/pending-bidder-refund-request" element={<PendingBiddersRefundRequest/>} />
                    <Route path="/bidders-eligible-for-refund" element={<BiddersEligibleForRefund/>} />
                    <Route path="/view-bailiff-refund-request" element={<ViewBailiffRefundRequest/>} />
                    <Route path="/edit-refund-request-status" element={<EditRefundRequestStatus/>} />
                    <Route path="/request-payment-claim" element={<RequestPaymentClaim/>}/>
                    <Route path="/update-claim-refund-request" element={<UpdateClaimRefundRequest/>}/>
                    <Route path="/view-list" element={<ViewClaimList/>}/>
                  </>
                    )
                  : (
                      ''
                    )}
                <Route path="/users-accounts-list" element={<UsersAccountsTable />} />
                <Route path="/view-payment" element={<ViewPayment />} />
                <Route path="/view-user-account" element={<ViewUserAccount />} />
                <Route path="/view-bidder" element={<ViewBidder />} />
                <Route path="/user-profile" element={<CreateUserProfile />} />
                <Route path="*" element={<Page404 />} />
              </Route>
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </>
  )
}

export default App
