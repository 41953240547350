/* eslint-disable no-trailing-spaces,@typescript-eslint/semi */
import requests from './httpService'
import axios from 'axios'

const {
  REACT_APP_IECMS_BK_BIDDER_API,
  REACT_APP_IECMS_API_USERNAME,
  REACT_APP_IECMS_API_PASSWORD,
  REACT_APP_IECMS_BK_AUCTION_API,
  REACT_APP_IECMS_MINIJUST_EMAIL,
  REACT_APP_IECMS_MINIJUST_PASS,
  REACT_APP_IECMS_BK_AUCTION_VALIDATE_API
  // RAECT_APP_IECMS_VALIDATE_BAILIFF_API

} = process.env

let cancelToken: any

const InstitutionUserService = {
  async InstitutionUserLogin (body: unknown) {
    return await requests.post('/auth/login', body)
  },

  async VerifyOTP (body: unknown) {
    return await requests.post('/auth/verify-otp', body)
  },

  async CreateInstitution (body: unknown) {
    return await requests.post('/institution/create', body)
  },
  async SendRefundRequest (body: unknown) {
    return await requests.post('/add-refund-request', body)
  },
  async SendRefundLink (body: unknown) {
    return await requests.post('/check-and-send-refund-request-link', body)
  },

  async CreateUsersAccount (body: unknown) {
    return await requests.post('/auth/register', body)
  },

  async getBKBidderDetails (body: any) {
    const bidder_data = await axios.post(REACT_APP_IECMS_BK_BIDDER_API as string, body, {
      auth: {
        username: REACT_APP_IECMS_API_USERNAME as string,
        password: REACT_APP_IECMS_API_PASSWORD as string
      }
    })
    return bidder_data
  },

  async getBKAuctionDetails (body: unknown) {
    const auction_data = await axios.post(REACT_APP_IECMS_BK_AUCTION_API as string, body, {
      auth: {
        username: REACT_APP_IECMS_API_USERNAME as string,
        password: REACT_APP_IECMS_API_PASSWORD as string
      }
    })
    return auction_data
  },

  async CreateBidSecurity (body: unknown) {
    return await requests.post('/bid-security/upload', body, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  },

  async GetBidSecurityList (
    limit: number,
    page: number,
    search?: string,
    status?: string,
    property_id?: string,
    payer_code?: string
  ) {
    let url = `/bidders-bids/view-all?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    status ? (url = url + `&status=${status}`) : ''
    // eslint-disable-next-line
    property_id ? (url = url + `&property_id=${property_id}`) : ''
    // eslint-disable-next-line
    payer_code ? (url = url + `&payer_code=${payer_code}`) : ''
    // eslint-disable-next-line
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async viewBidSecuritiesAndPaymentList (
    limit: number,
    page: number,
    search?: string,
    status?: string,
    property_id?: string,
    payer_code?: string
  ) {
    let url = `/bid-security/view-bid-securities?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    status ? (url = url + `&status=${status}`) : ''
    // eslint-disable-next-line
    property_id ? (url = url + `&property_id=${property_id}`) : ''
    // eslint-disable-next-line
    payer_code ? (url = url + `&payer_code=${payer_code}`) : ''
    // eslint-disable-next-line
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async viewCertifiedCheck (
    limit: number,
    page: number,
    search?: string,
    status?: string,
    property_id?: string
  ) {
    let url = `/bid-security/view-certified-check?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    status ? (url = url + `&status=${status}`) : ''
    // eslint-disable-next-line
    property_id ? (url = url + `&property_id=${property_id}`) : ''
    // eslint-disable-next-line
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async viewDigitalPayments (
    limit: number,
    page: number,
    search?: string,
    status?: string,
    property_id?: string
  ) {
    let url = `/bid-security/view-digital-payments?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    status ? (url = url + `&status=${status}`) : ''
    // eslint-disable-next-line
    property_id ? (url = url + `&property_id=${property_id}`) : ''
    // eslint-disable-next-line
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async viewBidGuarantee (
    limit: number,
    page: number,
    search?: string,
    status?: string,
    property_id?: string
  ) {
    let url = `/bid-security/view-bid-guarantee?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    status ? (url = url + `&status=${status}`) : ''
    // eslint-disable-next-line
    property_id ? (url = url + `&property_id=${property_id}`) : ''
    // eslint-disable-next-line
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async viewEligibleForRefund (
    limit: number,
    page: number,
    search?: string,
    status?: string,
    property_id?: string
  ) {
    let url = `/bid-security/view-eligible-bidders-for-refund?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    status ? (url = url + `&status=${status}`) : ''
    // eslint-disable-next-line
    property_id ? (url = url + `&property_id=${property_id}`) : ''
    // eslint-disable-next-line
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async GetBidSecurityListInstitutions (
    limit: number,
    page: number,
    search?: string,
    status?: string,
    property_id?: string
  ) {
    let url = `/bid-security/view-docs?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    status ? (url = url + `&status=${status}`) : ''
    // eslint-disable-next-line
    property_id ? (url = url + `&property_id=${property_id}`) : ''
    // eslint-disable-next-line
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async EditBidSecurity (bsId: string, body: unknown) {
    return await requests.put('/bid-security/edit/' + bsId, body, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  },

  async getInstitutionList (
    limit: number,
    page: number,
    search?: string,
    type?: string,
    from?: string,
    to?: string
  ) {
    let url = `/institution/view-all?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    type ? (url = url + `&type=${type}`) : ''
    // eslint-disable-next-line
    from ? (url = url + `&from=${from}`) : ''
    // eslint-disable-next-line
    to ? (url = url + `&to=${to}`) : ''

    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async EditInstitution (institurionId: string, body: unknown) {
    return await requests.put('/institution/edit/' + institurionId, body)
  },

  async EditProfile (body: unknown) {
    return await requests.put('/auth/update-profile', body)
  },

  async getUserAccountsList (
    limit: number,
    page: number,
    search?: string,
    type?: string,
    from?: string,
    to?: string
  ) {
    let url = `/auth/view-accounts?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    type ? (url = url + `&type=${type}`) : ''
    // eslint-disable-next-line
    from ? (url = url + `&from=${from}`) : ''
    // eslint-disable-next-line
    to ? (url = url + `&to=${to}`) : ''
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async getBidderList (limit: number, page: number, search?: string, from?: string, to?: string) {
    let url = `/bidders-bids/bidders?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    from ? (url = url + `&from=${from}`) : ''
    // eslint-disable-next-line
    to ? (url = url + `&to=${to}`) : ''
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async getPayemntsList (limit: number, page: number, search?: string, from?: string, to?: string) {
    let url = `/payment/view?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    from ? (url = url + `&from=${from}`) : ''
    // eslint-disable-next-line
    to ? (url = url + `&to=${to}`) : ''
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async DeleteBidSecurityById (id: string) {
    return await requests.delete('/bid-security/' + id + '/delete')
  },

  async updateRefundRequestStatus(payload: {
    RefundRequests: Array<{ transaction_reference: string }>
  }): Promise<any> {
    try {
      const response = await requests.put('/refund/update-refund-request-status', payload);
      return response.data;
    } catch (error) {
      // Handle error appropriately
      throw new Error('Failed to update refund request status.');
    }
  },

  async editRefundRequestStatusMessage(payload: {
    RefundRequests: Array<{ transaction_reference: string }>
  }): Promise<any> {
    try {
      const response = await requests.put('/refund/edit-refund-request-status_message', payload);
      return response.data;
    } catch (error) {
      // Handle error appropriately
      throw new Error('Failed to update refund request status.');
    }
  },

  async updateBailiffRefundRequestStatus(payload: {
    BailiffRefundRequests: Array<{ transaction_reference: string }>
  }): Promise<any> {
    try {
      const response = await requests.put('/bailiff/update-bailiff-refund-request-status', payload);
      return response.data;
    } catch (error) {
      // eslint-disable-next-line max-len
      throw new Error('Failed to update refund request status.');
    }
  },

  async TriggerPayment (body: unknown, token: string) {
    return await requests.post('/bid-security/trigger-payment', body, {
      headers: {
        // eslint-disable-next-line quote-props
        'Authorization': token
      }
    })
  },

  async TriggerRefundRequest(body: unknown, token: string) {
    return await requests.post('/refund/add-refund-request', body, {
      headers: {
        Authorization: token
      }
    })
  },

  async TriggerUpdateRefundRequestForBidders(body: unknown, token: string) {
    return await requests.put('/refund/update-refund-request-for-bidders', body, {
      headers: {
        Authorization: token
      }
    })
  },

  async SendRefundRequestLink(body: unknown, token: string) {
    return await requests.post('/refund/check-and-send-refund-request-link', body, {
      headers: {
        Authorization: token
      }
    })
  },

  async BailiffTriggerRefundRequest(body: unknown, token: string) {
    return await requests.post('/bailiff/send_bailiff-refund-request', body, {
      headers: {
        Authorization: token
      }
    })
  },

  async FetchToken () {
    const res = await requests.post('/auth/login', {
      email: REACT_APP_IECMS_MINIJUST_EMAIL,
      password: REACT_APP_IECMS_MINIJUST_PASS
    })

    return res.token
  },

  async GetPayment (bill_id: string, token: string) {
    const res = await requests.get(`/payment/one/${bill_id}`, {
      headers: {
        // eslint-disable-next-line quote-props
        'Authorization': token
      }
    })

    return res.data
  },

  // eslint-disable-next-line max-len
  async GetRefundRequestList (limit: number, page: number, search?: string, from?: string, to?: string) {
    let url = `/refund/view-all-refund-request?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    from ? (url = url + `&from=${from}`) : ''
    // eslint-disable-next-line
    to ? (url = url + `&to=${to}`) : ''
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  // eslint-disable-next-line max-len
  async GetBailiffRefundRequestList (limit: number, page: number, search?: string, from?: string, to?: string) {
    let url = `/bailiff/view-new-bailiff-refund-requests?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    from ? (url = url + `&from=${from}`) : ''
    // eslint-disable-next-line
    to ? (url = url + `&to=${to}`) : ''
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  // eslint-disable-next-line max-len
  async GetRefundRequestListByStatus (limit: number, page: number, search?: string, from?: string, to?: string) {
    let url = `/refund/view-all-refund-request-by-status?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    from ? (url = url + `&from=${from}`) : ''
    // eslint-disable-next-line
    to ? (url = url + `&to=${to}`) : ''
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  // eslint-disable-next-line max-len
  async GetRefundRequestListByTransfer (limit: number, page: number, search?: string, from?: string, to?: string) {
    let url = `/refund/view-all-bailiff-refund-request-by-transfer?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    from ? (url = url + `&from=${from}`) : ''
    // eslint-disable-next-line
    to ? (url = url + `&to=${to}`) : ''
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  // eslint-disable-next-line max-len
  async GetBailiffRefundRequestListByStatus (limit: number, page: number, search?: string, from?: string, to?: string) {
    let url = `/bailiff/view-all-bailiff-refund-request-by-status?limit=${limit}&page=${page}`
    // eslint-disable-next-line
    search ? (url = url + `&search=${search}`) : ''
    // eslint-disable-next-line
    from ? (url = url + `&from=${from}`) : ''
    // eslint-disable-next-line
    to ? (url = url + `&to=${to}`) : ''
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    return await requests.get(url, { cancelToken: cancelToken.token })
  },

  async ChangePassword (body: {
    old_password: string
    new_password: string
    confirm_password: string
  }) {
    return await requests.post('/auth/change-password', body)
  },

  async Logout () {
    return await requests.post('/auth/logout', {})
  },

  async SAdminStats () {
    return await requests.get('/statistics/sadmin', {})
  },

  async MFinanceStats () {
    return await requests.get('/statistics/mfinance', {})
  },

  async CountNumberOfNewRequest () {
    return await requests.get('/refund/count-refund-request', {})
  },

  async MFinanceRefundStats() {
    return await requests.get('/statistics/mFinanceRefund', {})
  },

  async RefundRequestStats() {
    return await requests.get('/statistics/refundStats', {})
  },

  async bailiffRefundRequestStats() {
    return await requests.get('/statistics/mBailiffRefundStat', {})
  },

  async InstitutionStats () {
    return await requests.get('/statistics/institution', {})
  },

  async GetUsageBS (ref_number: string) {
    return await requests.get(`/bid-security/used/${ref_number}`)
  },
  async GetBillData (bill_id: string) {
    return await requests.get(`/bid-security/bill/${bill_id}`)
  },
  async GetBidsDetailsForRefund(ref_number: string) {
    return await requests.get(`/bid-security/validate/${ref_number}`)
  },
  async createOtpCode(body: unknown) {
    return await requests.post('/refund/validate-otp', body)
  },
  async validateRefundRequestByRefNumberAndStatus(body: unknown) {
    return await requests.post('/refund/validate-refund-request-by-reference-number-and-status', body)
  },
  async validateRefundRequest(body: unknown) {
    return await requests.post('/refund/validate-refund-request', body)
  },
  async bailiffCreateOtpCode(body: unknown) {
    return await requests.post('/bailiff/bailiff_validate_otp', body)
  },
  async verifyOtpToSendRequest(body: unknown) {
    return await requests.post('/refund/verify-otp', body)
  },
  async verifyOtpForRequestedRefund(body: unknown) {
    return await requests.post('/refund/verify-otp-for-requested-refund', body)
  },
  async bailiffVerifyOtpToSendRequest(body: unknown) {
    return await requests.post('/bailiff/bailiff_verify-otp', body)
  },
  async getLastInsertedData() {
    return await requests.get('/refund/get-data')
  },

  async getBailiffLastInsertedData() {
    return await requests.get('/bailiff/get_last_inserted_data')
  },

  async RefundRequestConfirmation(body: unknown) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    return await requests.put('/refund/refund-request-confirmation', body)
  },

  async BailiffRefundRequestConfirmation(body: unknown) {
    return await requests.put('/bailiff/bailiff_refund-request-confirmation', body)
  },

  async loadBidsDetailsForRefund(ref_number: string) {
    return await requests.get(`/refund/refund_request/bidSecurityReference/${ref_number}`)
  },

  async loadBiddersAuctionDetails(ref_number: string) {
    return await requests.get(`/refund_request/requestBidSecurityFees/${ref_number}`)
  },

  async loadWinnerBidderDetailsForRefund(ref_number: string) {
    return await requests.get(`bailiff/bailiff_refund_request/bidSecurityReference/${ref_number}`)
  },

  async getBidderDetails (body: {
    property_id: string
    nid: string
    phone_number: string
  }) {
    return await requests.post('/refund/validate-bidder', body)
  },

  async validateRequestWhenOtpExpired(body: unknown) {
    return await requests.post('/refund/validate-request-when-otp-expired', body)
  },

  async bailiffValidateRequestWhenOtpExpired(body: unknown) {
    return await requests.post('/bailiff/bailiff-validate-request-when-otp-expired', body)
  },

  // https://cyamunara.gov.rw/oa-de/validate-bailiff

  async validateBailiffDetailsOnAuction(prpParam: any, ref_number: any) {
    return await axios.post('https://cyamunara.gov.rw/oa-de/validate-bailiff', {
      prp: prpParam,
      bidSecurityReferenceNumber: ref_number
    }, {
      auth: {
        username: process.env.REACT_APP_IECMS_API_USERNAME as string,
        password: process.env.REACT_APP_IECMS_API_PASSWORD as string
      },
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0'
      }
    });
  },

  async validateBidderForRefund(ref_number: any) {
    return await axios.post(REACT_APP_IECMS_BK_AUCTION_VALIDATE_API as string, {
      bidSecurityReferenceNumber: ref_number
    }, {
      auth: {
        username: process.env.REACT_APP_IECMS_API_USERNAME as string,
        password: process.env.REACT_APP_IECMS_API_PASSWORD as string
      },
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0'
      }
    });
  }
}

export default InstitutionUserService
